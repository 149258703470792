import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { getPartnerListResponse } from 'libs/@types/api'
import NEW_API from 'libs/api/api'
import API from 'libs/api/index'
import { shuffleBroadcastList } from 'libs/utils'
import {
  REQ_GET_BROADCAST_LIST_OF_PARTNER,
  REQ_GET_EVENT_BROADCAST_LIST,
  REQ_GET_PARTNER_LIST,
  REQ_GET_RECENT_BROADCAST_LIST,
  REQ_GET_SCHEDULE_LIST,
  REQ_GET_SCHEDULE_LIST_OF_PARTNER,
  RES_GET_BROADCAST_LIST_OF_PARTNER,
  RES_GET_EVENT_BROADCAST_LIST,
  RES_GET_PARTNER_LIST,
  RES_GET_RECENT_BROADCAST_LIST,
  RES_GET_SCHEDULE_LIST,
  RES_GET_SCHEDULE_LIST_OF_PARTNER,
} from './app.store'

function* getPartnerListSaga() {
  const response: getPartnerListResponse = yield call(API.getPartnerList)
  yield put(RES_GET_PARTNER_LIST(response))
}

function* getRecentBroadcast({ payload }: PayloadAction<{ renderPartnerId: string | null }>) {
  const response = yield call(NEW_API.getBroadcastListAPI, { partnerId: payload.renderPartnerId })
  yield put(RES_GET_RECENT_BROADCAST_LIST(response))
}

function* getScheduleBroadcast({ payload }: PayloadAction<{ renderPartnerId: string | null }>) {
  const response = yield call(NEW_API.getBroadcastListAPI, {
    partnerId: payload.renderPartnerId,
    isScheduled: true,
    order: 'asc',
  })
  yield put(RES_GET_SCHEDULE_LIST(response))
}

function* getPartnerBroadcast({ payload }: PayloadAction<{ partnerId: string }>) {
  const response = yield call(API.getBroadcastList, {
    partnerId: payload.partnerId,
    returnLimit: 100,
  })
  yield put(RES_GET_BROADCAST_LIST_OF_PARTNER(response))
}

function* getPartnerScheduleBroadcast({ payload }: PayloadAction<{ partnerId: string }>) {
  const response = yield call(API.getBroadcastList, {
    partnerId: payload.partnerId,
    isScheduled: true,
    order: 'asc',
  })

  yield put(RES_GET_SCHEDULE_LIST_OF_PARTNER(response))
}

function* getEventBroadcast() {
  const response = yield call(API.getEventBroadcast)

  const shuffledList = shuffleBroadcastList(response.items)
  const processedResponse = {
    ...response,
    items: response.useShuffleOrder ? shuffledList : response.items,
  }

  yield put(RES_GET_EVENT_BROADCAST_LIST(processedResponse))
}

export default function* appSaga() {
  yield takeEvery(REQ_GET_PARTNER_LIST, getPartnerListSaga)
  yield takeEvery(REQ_GET_RECENT_BROADCAST_LIST, getRecentBroadcast)
  yield takeEvery(REQ_GET_SCHEDULE_LIST, getScheduleBroadcast)
  yield takeEvery(REQ_GET_BROADCAST_LIST_OF_PARTNER, getPartnerBroadcast)
  yield takeEvery(REQ_GET_SCHEDULE_LIST_OF_PARTNER, getPartnerScheduleBroadcast)
  yield takeEvery(REQ_GET_EVENT_BROADCAST_LIST, getEventBroadcast)
}
