import { KinesisApiParams, KinesisApiRequest, KinesisApiResponse } from 'libs/@types/api'
import kinesisClient from './kinesis.client'
import proxyApiClient from './proxy.client'

const KinesisAPI = {
  putRecord: async ({ params, actionName }: KinesisApiParams['putRecord']) => {
    const DELAY_TIME = 2000
    const MAX = 5

    for (let i = 0; i < MAX; i++) {
      try {
        await kinesisClient.put(`/${actionName}`, params)
        return
      } catch (error) {
        if (i < MAX - 1) {
          await new Promise((res) => setTimeout(res, DELAY_TIME))
        }
      }
    }
  },
  Enter: async ({ appsync, kinesis }: KinesisApiParams['Enter']) => {
    const body = { appsync, kinesis: btoa(JSON.stringify(kinesis)) }
    const res = await proxyApiClient.post<KinesisApiRequest['Enter'], KinesisApiResponse['Enter']>(
      `/setJoinUser`,
      body,
    )

    const data = res.data

    return data
  },
  GetRoom: async ({
    roomId,
  }: KinesisApiParams['GetRoom']): Promise<KinesisApiResponse['GetRoom']['data']['getRoom']> => {
    return await proxyApiClient
      .get<KinesisApiResponse['GetRoom']>(`/getRoom?broadcastId=${roomId}`)
      .then((result) => {
        if (result.data.data.getRoom) {
          return result.data.data.getRoom
        }
        return null
      })
      .catch(() => {
        return null
      })
  },
}

export default KinesisAPI
