/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  lastKinesisSentTime: '', // new Date().toISOString()
  kinesisData: {} as IKinesisRecord,
  isRunningKinesisLoop: false,
  kinesisLoopCount: 0, // kinesisLoop 안의 while 문
  playTimeMS: 0, // 30초 이전 play & pause 일어날 때 실제 시청 ms 더해서 보관
}

const kinesisStore = createSlice({
  name: 'kinesis',
  initialState,
  reducers: {
    REQ_KINESIS_PRE_PAGE_VIEW() {},
    REQ_KINESIS_PAGE_VIEW() {},
    REQ_KINESIS_PLAYING(state, { payload }: PayloadAction<string>) {},
    REQ_KINESIS_CHAT(state, { payload }: PayloadAction<ChatCountPayload>) {},
    REQ_KINESIS_CLICK_BANNER(state, { payload }: PayloadAction<{ bannerId: string }>) {},
    REQ_KINESIS_REACTION(state, { payload }: PayloadAction<number>) {},
    REQ_KINESIS_CLICK_QR_POPUP(
      state,
      { payload }: PayloadAction<{ qrType: 'chatButton' | 'productButton'; productId?: string }>,
    ) {},
    REQ_KINESIS_FAST_FORWARD(state, { payload }: PayloadAction<number>) {},
    REQ_KINESIS_REWIND(state, { payload }: PayloadAction<number>) {},
    REQ_KINESIS_ENTER(state, { payload }: PayloadAction<AppSync>) {},
    REQ_KINESIS_PAUSE() {},
    REQ_KINESIS_GET_ROOM(state, { payload }: PayloadAction<KinesisGetRoom>) {},
    REQ_KINESIS_END() {},
    REQ_KINESIS_RESTART() {},
    UPDATE_KINESIS_LAST_SENT_TIME(state, { payload }: PayloadAction<string>) {
      state.lastKinesisSentTime = payload
    },
    UPDATE_KINESIS_DATA(state, { payload }: PayloadAction<IKinesisRecord>) {
      state.kinesisData = payload
    },
    UPDATE_KINESIS_LOOP_STATUS(state, { payload }: PayloadAction<boolean>) {
      state.isRunningKinesisLoop = payload
    },
    UPDATE_KINESIS_LOOP_COUNT(state, { payload }: PayloadAction<number>) {
      state.kinesisLoopCount = payload
    },
  },
})

export const kinesis = kinesisStore.reducer
export const {
  REQ_KINESIS_PAGE_VIEW,
  REQ_KINESIS_PRE_PAGE_VIEW,
  REQ_KINESIS_ENTER,
  REQ_KINESIS_REACTION,
  REQ_KINESIS_CHAT,
  REQ_KINESIS_CLICK_QR_POPUP,
  REQ_KINESIS_CLICK_BANNER,
  REQ_KINESIS_GET_ROOM,
  REQ_KINESIS_END,
  REQ_KINESIS_PAUSE,
  REQ_KINESIS_RESTART,
  REQ_KINESIS_FAST_FORWARD,
  REQ_KINESIS_REWIND,
  UPDATE_KINESIS_LAST_SENT_TIME,
  UPDATE_KINESIS_DATA,
  UPDATE_KINESIS_LOOP_STATUS,
  UPDATE_KINESIS_LOOP_COUNT,
} = kinesisStore.actions
