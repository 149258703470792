import { InternalAxiosRequestConfig } from 'axios'
import ENV from '../config'
import HttpClient from './http.client'

class kinesisApiClient extends HttpClient {
  public constructor() {
    super(ENV.DATA_GW_URL)

    this.initializeRequestInterceptor()
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private handleRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers['Content-Type'] = 'application/json'
    return config
  }
}

const kinesisClient = new kinesisApiClient()

export default kinesisClient
