import { call, delay, put, select, takeEvery } from '@redux-saga/core/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  BROADCAST_ID_PREFIX,
  BROADCAST_STATE_CODE,
  BROADCAST_TYPE_CODE,
  KINESIS_EVENT_NAME_LIST,
} from 'libs/@types/enums'
import KinesisAPI from 'libs/api/kinesis'
import { getKinesis, updatePlayStart } from 'libs/kinesis/kinesis'
import { UPDATE_ROOM } from 'libs/store/player/player.store'
import { getTimeDiff } from 'libs/utils'
import { errorGuard, RootState } from '..'
import {
  REQ_KINESIS_CHAT,
  REQ_KINESIS_CLICK_BANNER,
  REQ_KINESIS_CLICK_QR_POPUP,
  REQ_KINESIS_END,
  REQ_KINESIS_ENTER,
  REQ_KINESIS_FAST_FORWARD,
  REQ_KINESIS_GET_ROOM,
  REQ_KINESIS_PAUSE,
  REQ_KINESIS_PRE_PAGE_VIEW,
  REQ_KINESIS_REACTION,
  REQ_KINESIS_RESTART,
  REQ_KINESIS_REWIND,
  UPDATE_KINESIS_DATA,
  UPDATE_KINESIS_LAST_SENT_TIME,
  UPDATE_KINESIS_LOOP_COUNT,
  UPDATE_KINESIS_LOOP_STATUS,
} from './kinesis.store'

const PLAY_TIME_IN_SECONDS = 30

export function* getRecord() {
  const selectedBroadcast = yield select((state: RootState) => state.player.selectedBroadcast)
  const { player } = yield select((state: RootState) => state.player)

  /* lk & vod인 경우 kinesis도 캐치업VOD 데이터로 변환해서 전송해야됨 */
  let convertedBroadcastId = selectedBroadcast.broadcastId
  let convertedBroadcastTypeCode = selectedBroadcast.broadcastType
  if (
    selectedBroadcast.broadcastStateCode === BROADCAST_STATE_CODE.VOD &&
    /^lk/.test(selectedBroadcast.broadcastId)
  ) {
    convertedBroadcastId = convertedBroadcastId.replace(
      BROADCAST_ID_PREFIX.LIVE,
      BROADCAST_ID_PREFIX.CATCHUP_VOD,
    )
    convertedBroadcastTypeCode = BROADCAST_TYPE_CODE.CATCHUP_VOD
  }
  const defaultData = getKinesis()
  const record: IKinesisRecord = {
    ...defaultData,
    dt: new Date().toISOString(),
    broadcastCategoryId1: selectedBroadcast.majorCategory,
    broadcastCategoryId2: selectedBroadcast.minorCategory,
    broadcastStateCode: selectedBroadcast.broadcastStateCode,
    broadcastTypeCode: convertedBroadcastTypeCode,
    broadcastId: convertedBroadcastId,
    partnerId: selectedBroadcast.partnerId,
    serviceAdminId: selectedBroadcast.serviceAdminId,
    roomId: selectedBroadcast.roomId,
    seekTime: selectedBroadcast.isSchedule
      ? -1
      : player?.getCurrentTime()
      ? Math.floor(player.getCurrentTime())
      : 0,
    playingState: player?.getState() ?? 'idle',
  }

  if (selectedBroadcast.serviceManagerId) {
    record.serviceManagerId = selectedBroadcast.serviceManagerId
  }

  if (selectedBroadcast.serviceOperatorId) {
    record.serviceOperatorId = selectedBroadcast.serviceOperatorId
  }

  return record
}

// prePageView, 라이브 방송 이전 standby, ready, accepted 상태일 때만 동작
function* kinesisPrePageView() {
  const record: IKinesisRecord = yield getRecord()
  record.prePageViewCount = 1
  delete record.seekTime
  delete record.playingState
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.PRE_PAGE_VIEW })
}

// pageView, vod 혹은 onair 상태인 방송 들어갔을 때만 동작
function* kinesisEnter({ payload }: PayloadAction<AppSync>) {
  const record: IKinesisRecord = yield getRecord()
  record.playTimeV2 = PLAY_TIME_IN_SECONDS
  record.timeSinceStart = getTimeDiff(record.playStart)

  const appSyncData = {
    isAdmin: false,
    roomId: payload.roomId,
    input: {
      isLive: record.broadcastStateCode === 'onair',
      message: '',
      roomId: payload.roomId,
      messageType: 2,
      partnerId: record.partnerId,
      userId: record.memberId,
      userName: record.memberId,
    },
  }

  const res = yield call(KinesisAPI.Enter, {
    appsync: appSyncData,
    kinesis: {
      ...record,
      viewCount: 1,
    },
  })

  res?.data?.currentDt && updatePlayStart(res.data.currentDt)

  yield put(UPDATE_KINESIS_DATA(record))
  yield put(UPDATE_KINESIS_LAST_SENT_TIME(''))
  yield put(UPDATE_KINESIS_LOOP_COUNT(0))
  yield kinesisLoop()
}

function* kinesisLoop() {
  const kinesisLoopCount = yield select((state: RootState) => state.kinesis.kinesisLoopCount)
  let loopCount = kinesisLoopCount ? kinesisLoopCount : 0
  let isPlayTimeRecordEnd = false

  while (true) {
    yield delay(PLAY_TIME_IN_SECONDS * 1000)
    loopCount++
    const record: IKinesisRecord = yield getRecord()

    if (validateSkipPlayTimeRecord(record.playingState)) {
      yield put(UPDATE_KINESIS_LOOP_STATUS(false))
      break
    }

    const lastSentTime = yield select((state: RootState) => state.kinesis.lastKinesisSentTime)
    const timeDiff = getTimeDiff(lastSentTime)
    if (timeDiff < PLAY_TIME_IN_SECONDS) {
      break
    } else {
      recordPlayTime(record)
      yield put(UPDATE_KINESIS_LOOP_STATUS(true))
      yield put(UPDATE_KINESIS_LAST_SENT_TIME(record.dt))
    }
  }

  function validateSkipPlayTimeRecord(playingState: string | undefined) {
    /* 첫 complete 플레이 상태에서는 플레이타임을 기록해야 하기 때문에 true값 대입은 실행하지 않음 */
    if (playingState !== 'complete') {
      isPlayTimeRecordEnd = false
    }

    return (
      !playingState || playingState === 'paused' || playingState === 'error' || isPlayTimeRecordEnd
    )
  }

  function recordPlayTime(record: IKinesisRecord) {
    record.playTimeV2 = loopCount === 1 ? 0 : PLAY_TIME_IN_SECONDS
    record.timeSinceStart = getTimeDiff(record.playStart)

    const _record = JSON.stringify(record)
    KinesisAPI.putRecord({
      params: _record,
      actionName: KINESIS_EVENT_NAME_LIST.PLAYING,
    })

    isPlayTimeRecordEnd = record.playingState === 'complete' ? true : isPlayTimeRecordEnd
  }
}

/**
 * 플레이어를 이탈할 때 마지막 상태 & playTimeV2 보내는 로직. 완료
 */
function* kinesisEnd() {
  const { lastKinesisSentTime } = yield select((state: RootState) => state.kinesis)
  const { isRunningKinesisLoop } = yield select((state: RootState) => state.kinesis)
  yield put(UPDATE_KINESIS_LOOP_COUNT(0))
  const record: IKinesisRecord = yield getRecord()
  record.timeSinceStart = getTimeDiff(record.playStart)
  record.playingState = 'complete'
  if (isRunningKinesisLoop) {
    record.playTimeV2 = getTimeDiff(lastKinesisSentTime)
    yield put(UPDATE_KINESIS_LOOP_STATUS(false))
  } else {
    record.playTimeV2 = 0
  }

  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.END })
}

/**
 * 플레이어가 play -> pause 일 때 playTimeV2 보내고 loop 중지. 완료
 */
function* kinesisPause() {
  const { lastKinesisSentTime } = yield select((state: RootState) => state.kinesis)
  const record: IKinesisRecord = yield getRecord()
  record.timeSinceStart = getTimeDiff(record.playStart)
  record.playingState = 'paused'
  const lastKinesisSentTimeDiff = getTimeDiff(lastKinesisSentTime)

  if (lastKinesisSentTimeDiff >= 1) {
    record.playTimeV2 = getTimeDiff(lastKinesisSentTime)
    yield put(UPDATE_KINESIS_LAST_SENT_TIME(record.dt))
  } else {
    record.playTimeV2 = 0
  }
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.PAUSE })
}

/**
 * 플레이어가 pause -> play 일 때 playTimeV2 보내고 loop 재시작. 완료
 */
function* kinesisRestart() {
  const record: IKinesisRecord = yield getRecord()
  record.timeSinceStart = getTimeDiff(record.playStart)
  record.playTimeV2 = 0
  record.playingState = 'playing'
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.PLAY })
  yield put(UPDATE_KINESIS_LAST_SENT_TIME(record.dt))
  yield kinesisLoop()
}

// 내비둬야함. 완료
function* kinesisGetRoom({ payload }: PayloadAction<KinesisGetRoom>) {
  const { roomId } = payload
  const roomData: Room = yield call(KinesisAPI.GetRoom, {
    roomId: roomId,
  })
  if (roomData) {
    yield put(UPDATE_ROOM({ roomData }))
  }
}

// 채팅 완료
function* kinesisChat({ payload }: PayloadAction<ChatCountPayload>) {
  const record: IKinesisRecord = yield getRecord()
  record.chatMessageCount = payload.chatMessageCount
  record.chatMessage = payload.chatMessage
  record.chatMessageId = payload.chatMessageId
  record.timeSinceStart = getTimeDiff(record.dt)
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.CHAT })
}

// likeClick 완료
function* kinesisReaction({ payload }: PayloadAction<number>) {
  const record: IKinesisRecord = yield getRecord()
  record.likeCount = payload
  record.timeSinceStart = getTimeDiff(record.dt)
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.LIKE_CLICK })
}

// qrClick(chat, product) 완료
function* kinesisClickQrPopup({
  payload,
}: PayloadAction<{ qrType: 'chatButton' | 'productButton'; productId?: string }>) {
  const record: IKinesisRecord = yield getRecord()
  record.qrPopupButtonType = payload.qrType
  record.timeSinceStart = getTimeDiff(record.dt)
  if (payload.qrType === 'productButton') {
    record.productId = payload.productId
  }
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.QR_CLICK })
}

// bannerClick 완료
function* kinesisClickBanner({ payload }: PayloadAction<{ bannerId: string }>) {
  const record: IKinesisRecord = yield getRecord()

  record.bannerId = payload.bannerId
  record.timeSinceStart = getTimeDiff(record.dt)

  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.BANNER_CLICK })
}

// 빨리감기
function* kinesisFastForward({ payload }: PayloadAction<number>) {
  const record: IKinesisRecord = yield getRecord()
  record.seekTime = record.seekTime ? record.seekTime + payload : payload
  record.timeSinceStart = getTimeDiff(record.dt)
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.FAST_FORWARD })
}

// 되감기
function* kinesisRewind({ payload }: PayloadAction<number>) {
  const record: IKinesisRecord = yield getRecord()
  record.seekTime =
    record.seekTime && record.seekTime - 10 >= 0 ? record.seekTime - payload : payload
  record.timeSinceStart = getTimeDiff(record.dt)
  const _record = JSON.stringify(record)
  KinesisAPI.putRecord({ params: _record, actionName: KINESIS_EVENT_NAME_LIST.REWIND })
}

export default function* kinesisSaga() {
  yield takeEvery(REQ_KINESIS_PRE_PAGE_VIEW, errorGuard(kinesisPrePageView))
  yield takeEvery(REQ_KINESIS_ENTER, errorGuard(kinesisEnter))
  yield takeEvery(REQ_KINESIS_REACTION, errorGuard(kinesisReaction))
  yield takeEvery(REQ_KINESIS_CHAT, errorGuard(kinesisChat))
  yield takeEvery(REQ_KINESIS_CLICK_QR_POPUP, errorGuard(kinesisClickQrPopup))
  yield takeEvery(REQ_KINESIS_CLICK_BANNER, errorGuard(kinesisClickBanner))
  yield takeEvery(REQ_KINESIS_GET_ROOM, errorGuard(kinesisGetRoom))
  yield takeEvery(REQ_KINESIS_END, errorGuard(kinesisEnd))
  yield takeEvery(REQ_KINESIS_PAUSE, errorGuard(kinesisPause))
  yield takeEvery(REQ_KINESIS_RESTART, errorGuard(kinesisRestart))
  yield takeEvery(REQ_KINESIS_FAST_FORWARD, errorGuard(kinesisFastForward))
  yield takeEvery(REQ_KINESIS_REWIND, errorGuard(kinesisRewind))
}
