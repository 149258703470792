export function _getDUID() {
  if (!window.tizen) return 'Web'
  const { duid }: { duid: string } = window.tizen.systeminfo.getCapabilities()
  return duid
}

function _getDevice() {
  if (!window.tizen) {
    return 'Web'
  }

  const productinfo = webapis.productinfo
  const modelCode = productinfo.getModel()

  return modelCode ?? 'desktop'
}

function _getIsMute() {
  if (!window.tizen) {
    return false
  }
  return !!window.tizen.tvaudiocontrol.isMute()
}

function _getUA() {
  const _ua = navigator.userAgent
  if (!window.tizen) {
    return _ua
  }

  const regex = /[)]/
  const modelCode = webapis.productinfo.getModelCode() ?? ''
  return _ua.replace(regex, ` ${modelCode})`)
}

function _initKinesis() {
  // 유저 정보
  const memberId = _getDUID()
  const duid = _getDUID()
  const ageCode = 'etc' as IKinesisRecord['ageCode']
  const genderCode = 'e' as IKinesisRecord['genderCode']
  const _os = 'tizen'
  const memberTypeCode = '0' as IKinesisRecord['memberTypeCode']
  const usedSource = 'SF-W-T'
  const fc = 'sauceTv'
  const userBrowser = 'Etc'
  const userAgent = _getUA()
  const modelTypeCode = _getDevice()

  // 세션 정보
  const dt = new Date().toISOString()
  const playStart = new Date().toISOString()
  const timeSinceStart = 0
  const isMute = _getIsMute()

  return {
    dt,
    os: _os,
    fc,
    duid,
    ageCode,
    genderCode,
    isMute,
    usedSource,
    userAgent,
    userBrowser,
    playStart,
    timeSinceStart,
    memberTypeCode,
    memberId,
    modelTypeCode,
  }
}

function getKinesis() {
  return _kinesis
}

function updatePlayStart(playStart: string) {
  _kinesis.playStart = playStart
}

const _kinesis = _initKinesis()

export { getKinesis, updatePlayStart }
