import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MESSAGE_PREFIX,
  PLAYER_MODE,
  ROOM_STATE,
  SAUCEFLEX_SDK_EVENT_NAME as EVENT_NAME,
  TYPE_MESSAGE,
} from 'libs/@types/enums'
import SauceflexSocketSDK, { DATA_NONE } from 'libs/sauceflexSocketSDK'
import { getUserToken } from 'libs/singleton'
import { RootState } from 'libs/store'
import { REQ_KINESIS_CHAT, REQ_KINESIS_REACTION } from 'libs/store/kinesis/kinesis.store'
import {
  INCREASE_LIKE_COUNT,
  INIT_NOTICE,
  INIT_REPLY,
  REQ_GET_SELECTED_BROADCAST,
  SET_SOCKET,
  UPDATE_CHAT,
  UPDATE_CHAT_LIST,
  UPDATE_INSERT,
  UPDATE_NOTICE,
  UPDATE_REPLY,
  UPDATE_ROOM_INTERVAL,
} from 'libs/store/player/player.store'
import { REQ_RESET_USER_SESSION } from 'libs/store/session/session.store'
import { getRandomNumber } from 'libs/utils'
import useBroadcastStatus from './useBroadcastStatus'

/**
 * @name useSauceflexSocketSDK
 * @returns {void} 없음
 */

export default function useSauceflexSocketSDK() {
  const selectedBroadcast = useSelector((state: RootState) => state.player.selectedBroadcast)
  const { broadcastId, partnerId, roomId } = selectedBroadcast ?? {}
  const sauceflexSocketSDK = useSelector((state: RootState) => state.player.sauceflexSocketSDK)
  const memberId = useSelector((state: RootState) => state.session.memberId)
  const nickname = useSelector((state: RootState) => state.session.nickname)

  const [playerMode, setPlayerMode] = useState<PLAYER_MODE | null>(null)
  const [roomState, setRoomState] = useState<ROOM_STATE | undefined>(undefined)

  const { isVod } = useBroadcastStatus()

  const dispatch = useDispatch()

  const updateBroadcastInfo = useCallback(() => {
    if (!broadcastId) return
    setTimeout(
      () => dispatch(REQ_GET_SELECTED_BROADCAST({ broadcastId })),
      getRandomNumber(1, 4) * 1000,
    )
  }, [broadcastId, dispatch])

  const updateRoom = useCallback(
    (data: RoomSocketData, isInit = false) => {
      const { joinUser, liveUserCount, room } = data

      roomState !== room.roomState && setRoomState(room.roomState)

      if (!sauceflexSocketSDK) return
      dispatch(
        UPDATE_ROOM_INTERVAL({
          room: {
            banUserInfo: room.banUserInfo,
            canChat: room.canChat,
            chatCounterInfo: room.chatCounterInfo,
            incomingCounterInfo: room.incomingCounterInfo,
            reactionCounterCalc: isInit ? 0 : undefined,
            reactionCounterInfo: room.reactionCounterInfo,
            roomState: room.roomState,
            userNick: joinUser[0]?.userNick ?? '',
          },
        }),
      )

      // liveUserCount 는 getRoom Event에서는 주지 않기 때문에 예외처리
      if (liveUserCount !== undefined) {
        // 실시간 접속자 수는 따로 처리하지 않고 진행
      }
    },
    [roomState, sauceflexSocketSDK, dispatch],
  )

  const liveCallbackEvent = useCallback(
    (eventName: EVENT_NAME, data: SocketData) => {
      switch (eventName) {
        /* 연결 이벤트 */
        case EVENT_NAME.INIT: {
          updateRoom(data as RoomSocketData, true)
          sauceflexSocketSDK?.onEventRegister(
            (data as RoomSocketData).socketUpdateRoomName,
            liveCallbackEvent,
          )
          sauceflexSocketSDK?.emitGetMessages(MESSAGE_PREFIX.CHAT)
          sauceflexSocketSDK?.emitGetMessages(MESSAGE_PREFIX.NOTICE)
          sauceflexSocketSDK?.emitGetMessages(MESSAGE_PREFIX.REPLY)
          sauceflexSocketSDK?.emitGetMessages(MESSAGE_PREFIX.ETC)
          break
        }
        case EVENT_NAME.CONNECT: {
          sauceflexSocketSDK?.init()
          break
        }
        case EVENT_NAME.DISCONNECT: {
          break
        }
        /* 룸 정보 이벤트 */
        case EVENT_NAME.GET_ROOM: {
          break
        }
        case EVENT_NAME.UPDATE_ROOM: {
          updateRoom(data as RoomSocketData)
          break
        }
        case EVENT_NAME.REFETCH_ROOM: {
          updateBroadcastInfo()
          break
        }
        /* 메시지 이벤트 */
        case EVENT_NAME.GET_MESSAGES: {
          const { messages, prefix } = data as GetMessagesSocketData
          switch (prefix) {
            case MESSAGE_PREFIX.CHAT: {
              dispatch(UPDATE_CHAT_LIST({ messages }))
              sauceflexSocketSDK?.onEventRegister(EVENT_NAME.MY_MESSAGE, liveCallbackEvent) // 채팅 입력 자기자신에게 오는 메시지(kinesis 를 위해)
              sauceflexSocketSDK?.onEventRegister(EVENT_NAME.REJECT_MESSAGE, liveCallbackEvent) // 메시지 도배시 에러(같은문자 + 1초미만 반복 입력)
              sauceflexSocketSDK?.onEventRegister(EVENT_NAME.INACTIVE_MESSAGE, liveCallbackEvent) // 메시지 비활성화 처리(관리자), 사용자는 inactive 상태 회신받음
              break
            }
            case MESSAGE_PREFIX.NOTICE: {
              dispatch(INIT_NOTICE({ messages }))
              break
            }
            case MESSAGE_PREFIX.REPLY: {
              dispatch(INIT_REPLY({ messages }))
              break
            }
            case MESSAGE_PREFIX.ETC: {
              const recentInsert = messages.find(
                (message) =>
                  message?.messageType === TYPE_MESSAGE.INSERT_SHOW ||
                  message?.messageType === TYPE_MESSAGE.INSERT_HIDE,
              )
              if (
                recentInsert?.messageType === TYPE_MESSAGE.INSERT_SHOW &&
                recentInsert?.data &&
                recentInsert?.data !== DATA_NONE
              ) {
                dispatch(UPDATE_INSERT(recentInsert?.data))
              } else {
                dispatch(UPDATE_INSERT(null))
              }
              break
            }
            default:
            // 그 외 Prefix 메시지는 처리하지 않음
          }
          switch (prefix) {
            case MESSAGE_PREFIX.NOTICE:
            case MESSAGE_PREFIX.REPLY:
            default:
          }
          break
        }
        case EVENT_NAME.REJECT_MESSAGE: {
          break
        }
        case EVENT_NAME.SEND_MESSAGE: {
          const messages = data as SendMessageSocketData
          messages.forEach((message) => {
            switch (message.messageType) {
              case TYPE_MESSAGE.USER:
              case TYPE_MESSAGE.ADMIN: {
                dispatch(UPDATE_CHAT(message))
                dispatch(
                  REQ_KINESIS_CHAT({
                    chatMessageCount: 1,
                    chatMessage: message?.message,
                    chatMessageId: message?.messageId,
                  }),
                )
                break
              }
              case TYPE_MESSAGE.INSERT_SHOW:
              case TYPE_MESSAGE.INSERT_HIDE: {
                dispatch(
                  UPDATE_INSERT(message.data && message.data !== DATA_NONE ? message.data : null),
                )
                break
              }
              case TYPE_MESSAGE.NOTICE_SHOW:
              case TYPE_MESSAGE.NOTICE_HIDE: {
                dispatch(UPDATE_NOTICE({ message }))
                break
              }
              case TYPE_MESSAGE.ANSWER: {
                dispatch(UPDATE_REPLY({ message }))
                break
              }
              case TYPE_MESSAGE.REFETCH: {
                updateBroadcastInfo()
                break
              }
              default:
            }
          })
          break
        }
        case EVENT_NAME.MY_MESSAGE: {
          // 자신에게만 응답되는 이벤트
          // const messages = data as SendMessageSocketData
          // const message = messages[0]
          // // TODO 키네시스 채팅 요청

          break
        }
        /* 좋아요 이벤트 */
        case EVENT_NAME.SEND_LIKE: {
          // 자신에게만 응답되는 이벤트
          const { value } = data as LikeSocketData
          dispatch(INCREASE_LIKE_COUNT(value))
          // TODO 키네시스 좋아요 요청
          dispatch(REQ_KINESIS_REACTION(value))
          break
        }
        case EVENT_NAME.MY_LIKE: {
          // // 자신에게만 응답되는 이벤트
          // const { value } = data as LikeSocketData
          // dispatch(INCREASE_LIKE_COUNT(value))
          // // TODO 키네시스 좋아요 요청
          // dispatch(REQ_KINESIS_REACTION(value))
          break
        }
        /* 금칙어, 차단사용자 이벤트 */
        case EVENT_NAME.UPDATE_BANNED_WORD: {
          // 소스TV에서는 제공하지 않는 기능
          break
        }
        case EVENT_NAME.UPDATE_BANNED_USER: {
          // 차단 사용자 처리는 기존 API 처리로 진행
          break
        }
        case EVENT_NAME.INACTIVE_MESSAGE: {
          // 채팅 얼리기 / 녹이기 처리는 기존 API 처리로 진행
          break
        }
        /* 구매인증 이벤트 (해당 처리 사용 안함) */
        case EVENT_NAME.PURCHASE_STATE:
        case EVENT_NAME.SET_PURCHASE:
        case EVENT_NAME.GET_PURCHASES:
        case EVENT_NAME.WIN_PURCHASES: {
          break
        }
        /* IP 차단 이벤트 */
        case EVENT_NAME.SET_BAN_IP: {
          // 차단 IP 추가 처리
          break
        }
        case EVENT_NAME.REMOVE_BAN_IP: {
          // 차단된 IP 삭제 처리
          break
        }
        case EVENT_NAME.GET_BAN_IP: {
          // 차단되어 있는 IP 리스트 획득 IPv4 처리
          break
        }
        case EVENT_NAME.REJECT_IP: {
          // 사용자 메시지 입력시 IP 가 차단되어 있으면 알려주는 용도(자기 자신) 처리
          break
        }
        /* 사용자 인증 이벤트 */
        case EVENT_NAME.TOKEN_PASS: {
          // 인증 성공일 경우 따로 처리 없이 진행
          break
        }
        case EVENT_NAME.INVALID_TOKEN: {
          dispatch(REQ_RESET_USER_SESSION())
          break
        }
        case EVENT_NAME.CREATE_ARCHIVED_CHAT:
        case EVENT_NAME.UPDATE_ARCHIVED_CHAT: {
          // 소스TV에서는 제공하지 않는 기능
          break
        }
        /* 알 수 없는 이벤트 */
        default: {
          break
        }
      }
    },
    [sauceflexSocketSDK, dispatch, updateBroadcastInfo, updateRoom],
  )

  const vodCallbackEvent = useCallback(
    (eventName: EVENT_NAME, data: Exclude<SocketData, ProductListSocketData>) => {
      switch (eventName) {
        /* 연결 이벤트 */
        case EVENT_NAME.INIT: {
          // VOD에서는 첫 시점에서만 데이터를 받고 중단
          updateRoom(data as RoomSocketData, true)
          sauceflexSocketSDK?.onEventRegister(
            (data as RoomSocketData).socketUpdateRoomName,
            vodCallbackEvent,
          )
          sauceflexSocketSDK?.emitGetMessages(MESSAGE_PREFIX.NOTICE)
          sauceflexSocketSDK?.emitGetMessages(MESSAGE_PREFIX.REPLY)
          break
        }
        case EVENT_NAME.CONNECT: {
          sauceflexSocketSDK?.init()
          break
        }
        case EVENT_NAME.DISCONNECT: {
          break
        }
        /* 룸 정보 이벤트 */
        case EVENT_NAME.GET_ROOM: {
          break
        }
        case EVENT_NAME.UPDATE_ROOM: {
          // VOD에서는 인터벌 데이터를 사용하지 않음
          break
        }
        case EVENT_NAME.REFETCH_ROOM: {
          updateBroadcastInfo()
          break
        }
        /* 메시지 이벤트 */
        case EVENT_NAME.GET_MESSAGES: {
          const { messages, prefix } = data as GetMessagesSocketData
          switch (prefix) {
            case MESSAGE_PREFIX.NOTICE: {
              dispatch(INIT_NOTICE({ messages }))
              break
            }
            case MESSAGE_PREFIX.REPLY: {
              dispatch(INIT_REPLY({ messages }))
              break
            }
            default:
            // 그 외 Prefix 메시지는 처리하지 않음
          }
          break
        }
        case EVENT_NAME.REJECT_MESSAGE: {
          // VOD에서는 따로 처리 없이 진행
          break
        }
        case EVENT_NAME.SEND_MESSAGE: {
          // VOD에서는 따로 처리 없이 진행
          break
        }
        case EVENT_NAME.MY_MESSAGE: {
          // 자신에게만 응답되는 이벤트, 따로 처리 없이 진행
          break
        }
        /* 좋아요 이벤트 */
        case EVENT_NAME.SEND_LIKE: {
          // 자신에게만 응답되는 이벤트, 따로 처리 없이 진행
          break
        }
        case EVENT_NAME.MY_LIKE: {
          // 자신에게만 응답되는 이벤트
          const { value } = data as LikeSocketData
          dispatch(INCREASE_LIKE_COUNT(value))
          // TODO 키네시스 좋아요 요청
          break
        }
        /* 금칙어, 차단사용자 이벤트 */
        case EVENT_NAME.UPDATE_BANNED_WORD: {
          // 소스TV에서는 제공하지 않는 기능
          break
        }
        case EVENT_NAME.UPDATE_BANNED_USER: {
          // 차단 사용자 처리는 기존 API 처리로 진행
          break
        }
        case EVENT_NAME.INACTIVE_MESSAGE: {
          // 채팅 얼리기 / 녹이기 처리는 기존 API 처리로 진행
          break
        }
        /* 구매인증 이벤트 (해당 처리 사용 안함) */
        case EVENT_NAME.PURCHASE_STATE:
        case EVENT_NAME.SET_PURCHASE:
        case EVENT_NAME.GET_PURCHASES:
        case EVENT_NAME.WIN_PURCHASES: {
          break
        }
        /* IP 차단 이벤트 */
        case EVENT_NAME.SET_BAN_IP: {
          // 차단 IP 추가 처리
          break
        }
        case EVENT_NAME.REMOVE_BAN_IP: {
          // 차단된 IP 삭제 처리
          break
        }
        case EVENT_NAME.GET_BAN_IP: {
          // 차단되어 있는 IP 리스트 획득 IPv4 처리
          break
        }
        case EVENT_NAME.REJECT_IP: {
          // 사용자 메시지 입력시 IP 가 차단되어 있으면 알려주는 용도(자기 자신) 처리
          break
        }
        /* 사용자 인증 이벤트 */
        case EVENT_NAME.TOKEN_PASS: {
          // 인증 성공일 경우 따로 처리 없이 진행
          break
        }
        case EVENT_NAME.INVALID_TOKEN: {
          // 인증 실패일 경우 따로 처리 없이 진행
          break
        }
        /* 알 수 없는 이벤트 */
        default: {
          break
        }
      }
    },
    [sauceflexSocketSDK, dispatch, updateBroadcastInfo, updateRoom],
  )

  const registerEvents = useCallback(() => {
    const callbackEvent = playerMode === PLAYER_MODE.LIVE ? liveCallbackEvent : vodCallbackEvent
    registerConnectEvents()
    registerRoomEvents()
    registerMessageEvents()
    registerLikeEvents()
    registerBannedEvents()
    registerAuthEvents()
    registerProductEvents()

    function registerConnectEvents() {
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.CONNECT, callbackEvent)
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.DISCONNECT, callbackEvent)
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.INIT, callbackEvent) // init의 callback 을 이용하여 updateRoom 을 이용해야한다. callbackEvent func 의 init 항목 참조.
    }
    function registerRoomEvents() {
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.GET_ROOM, callbackEvent)
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.UPDATE_ROOM, callbackEvent)
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.REFETCH_ROOM, callbackEvent) // refetch 가 필요할 시 서버에서 호출
    }
    function registerMessageEvents() {
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.GET_MESSAGES, callbackEvent) // 방 메시지 획득
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.SEND_MESSAGE, callbackEvent) // 채팅 입력, 리패치 메시지
    }
    function registerLikeEvents() {
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.SEND_LIKE, callbackEvent) // 좋아요 입력
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.MY_LIKE, callbackEvent) // 좋아요 입력 자기자신에게 오는 메시지(kinesis 를 위해)
    }
    function registerBannedEvents() {
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.UPDATE_BANNED_WORD, callbackEvent) // 금칙어 등록/삭제시 사용
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.UPDATE_BANNED_USER, callbackEvent) // 차단사용자 등록/삭제시 사용
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.INACTIVE_MESSAGE, callbackEvent) // 메시지 비활성화 처리(관리자), 사용자는 inactive 상태 회신받음.
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.SET_BAN_IP, callbackEvent) // 차단 IP 추가.
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.REMOVE_BAN_IP, callbackEvent) // 차단된 IP 삭제
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.GET_BAN_IP, callbackEvent) // 차단되어 있는 IP 리스트 획득 IPv4
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.REJECT_IP, callbackEvent) // 사용자 메시지 입력시 IP 가 차단되어 있으면 알려주는 용도(자기 자신)
    }
    function registerAuthEvents() {
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.TOKEN_PASS, callbackEvent) // 인증 성공일때 알려줌
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.INVALID_TOKEN, callbackEvent) // 인증 실패일때 알려줌.
    }
    function registerProductEvents() {
      sauceflexSocketSDK?.onEventRegister(EVENT_NAME.UPDATE_PRODUCT_LIST_DETAIL, callbackEvent)
    }
  }, [playerMode, sauceflexSocketSDK, liveCallbackEvent, vodCallbackEvent])

  const connect = useCallback(
    (token: string, userId: string, userNickname: string) => {
      if (!sauceflexSocketSDK) return null
      sauceflexSocketSDK.setUserInfo(token, userId, userNickname)
      sauceflexSocketSDK.connect()
    },
    [sauceflexSocketSDK],
  )

  const init = useCallback(
    (token: string, userId: string, userNickname: string) => {
      if (!sauceflexSocketSDK) return false
      if (
        sauceflexSocketSDK.token === token &&
        sauceflexSocketSDK.userId === userId &&
        sauceflexSocketSDK.userNickname === userNickname
      ) {
        return false
      }

      sauceflexSocketSDK.isConnect() && sauceflexSocketSDK.disconnect()
      sauceflexSocketSDK.initSocket()
      registerEvents()
      connect(token, userId, userNickname)
      return true
    },
    [sauceflexSocketSDK, registerEvents, connect],
  )

  useEffect(() => {
    if (!partnerId || !playerMode || !memberId || !nickname) return

    const token = getUserToken()
    token && init(token, memberId, nickname)
  }, [memberId, nickname, partnerId, playerMode, init])

  useEffect(() => {
    setPlayerMode(isVod ? PLAYER_MODE.VOD : PLAYER_MODE.LIVE)
  }, [isVod])

  useEffect(() => {
    if (!playerMode) return
    if (roomId && partnerId && !sauceflexSocketSDK) {
      dispatch(SET_SOCKET(new SauceflexSocketSDK(partnerId, roomId)))
    }
  }, [partnerId, playerMode, roomId, sauceflexSocketSDK, dispatch])
}
